import styled from 'styled-components'
import { theme } from '@/client/components'
const { borders, breakpoint, color, space } = theme

export const Content = styled.div`
  padding-bottom: ${space.xl3};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.lg} 0 ${space.xl3};
  }
`

export const WrapForm = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoint.lg}) {
    flex-direction: row;
    align-items: center;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${space.lg};
  margin-bottom: ${space.xl3};
  padding: ${space.xl};
  border-radius: ${borders.radius.lg};
  background-color: ${color.grayscale.lighter};

  @media (min-width: ${breakpoint.lg}) {
    width: 50%;
    margin-bottom: 0;
    padding: ${space.xl2};
    background-color: ${color.primary.darker};
  }
`

export const ShowPasswordButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 ${space.sm};
  height: 100%;
  color: ${color.grayscale.black};

  &:hover {
    color: ${color.primary.pure};
  }
`

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  gap: ${space.lg};
`

export const Aside = styled.aside`
  width: 100%;

  @media (min-width: ${breakpoint.lg}) {
    width: 40%;
    margin-left: 10%;
  }
`
