import { FC } from 'react'
import Page from '@/client/submodules/Page'
import { Form } from './Form'
import { LoginProps } from './types'

export const Login: FC<LoginProps> = ({ content, seo, header, footer }) => (
  <Page seo={seo} header={header} footer={footer}>
    <Form content={content} />
  </Page>
)
