import { assertValidEmail, assertMinlength, assertRequiredField, assertStrongPassword } from '@/client/utils'
import { requiredFields } from './form'

export const asserts = (name: string, value: string): string => {
  let fieldError = ''

  if (requiredFields.includes(name)) {
    fieldError = fieldError || assertRequiredField(value)
  }

  if (name === 'Email') {
    fieldError = fieldError || assertValidEmail(value)
  }

  if (name === 'Password') {
    fieldError = fieldError || assertMinlength(value, 3) || assertStrongPassword(value)
  }

  return fieldError
}
