import { FC, useState, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import Link from 'next/link'
import { parseCookies } from 'nookies'
import { Row, Col, Text, Breadcrumb, Input, Button, Icon, Link as LinkComp } from '@/client/components'
import { useWindowSize, useRouterPush } from '@/client/hooks'
import { Cookies } from '@/client/types'
import { ROUTER_PATH } from '@/client/utils'
import { updateCart, getUserCart, addCartItem, getCartItems } from '@/infra/services'
import { asserts } from './asserts'
import { initialForm, requiredFields } from './form'
import * as S from './styles'
import { FormCompProps, FormProps } from '../types'

export const Form: FC<FormCompProps> = ({ content }) => {
  const {
    breadcrumb,
    title,
    subtitle,
    label_email_field,
    label_password_field,
    label_login_button,
    label_forgot_password_link,
    aside
  } = content
  const { routerPush, defaultLocale, query, replace, back } = useRouterPush()
  const redirectRoute = query.redirect
  const windowSize = useWindowSize()
  const isLgBreakpoint = windowSize?.width > 768
  const [form, setForm] = useState<FormProps>(initialForm)
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [formError, setFormError] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleValidateForm = useCallback(() => {
    const validation = requiredFields.every((field) => !form[field].hasError)
    return !validation
  }, [form])

  const handleChange = useCallback((event) => {
    const {
      type,
      currentTarget: { name, value }
    } = event
    const error = asserts(name, value)

    setForm((oldForm) => ({
      ...oldForm,
      [name]: {
        dirty: oldForm[name].dirty || type === 'blur',
        value,
        error,
        hasError: Boolean(error)
      }
    }))
  }, [])

  const handleCart = useCallback(async () => {
    const cookies = parseCookies()

    // Get current cookies
    const cartId = cookies[Cookies.CART_ID]
    try {
      const res = await getUserCart(cartId)
      if (cartId && cartId !== res._id) {
        const { cart_items } = await getCartItems(cartId)
        for (const item of cart_items) {
          const { cart_item_selection } = item
          addCartItem(cart_item_selection, res._id)
        }

        // await addCartItem()
        return
      }
    } catch (error) {
      console.log(error)
    }

    // Get user Cart
    // Get cart Id from it
    // Check cookies cart is not equals userCart
    // Add update cart to current cart.

    if (cartId) {
      await updateCart(cartId, {})
      return
    }
  }, [])

  const handleSubmitForm = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setIsLoadingForm(true)
      const { Email, Password } = form
      Auth.signIn(Email?.value, Password?.value)
        .then(async () => {
          await handleCart()

          if (redirectRoute) {
            replace(String(redirectRoute))
            return
          }

          back()
        })
        .catch(async (error) => {
          const message = error instanceof Error ? error?.message : String(error)
          setFormError(`Login error: ${message}`)
          /**
           * Handle network errors and log out the user immediately
           */
          await Auth.signOut({ global: true })
        })
        .finally(() => {
          setIsLoadingForm(false)
        })
    },
    [form, replace, back, redirectRoute, handleCart]
  )

  const handleRegister = useCallback(() => routerPush(ROUTER_PATH.SIGNUP[defaultLocale]), [routerPush, defaultLocale])

  return (
    <S.Content>
      <Row>
        <Col>
          <Breadcrumb pages={breadcrumb} />
          <Text as='h2' color='primary' family='heading'>
            {subtitle}
          </Text>
          <Text as='h1' size='xxlg' family='heading' weight='bold' margin='0 0 2.5rem' itemProp='name'>
            {title}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <S.WrapForm>
            <S.Form onSubmit={handleSubmitForm}>
              <Input
                isBlock
                dimension='large'
                type='text'
                id='Email'
                name='Email'
                label={label_email_field}
                value={form?.Email?.value ?? ''}
                onChange={handleChange}
                onBlur={handleChange}
                hasError={form?.Email.hasError && form?.Email.dirty}
                error={form?.Email.error}
              />

              <Input
                isBlock
                dimension='large'
                type={showPassword ? 'text' : 'password'}
                id='Password'
                name='Password'
                label={label_password_field}
                value={form?.Password?.value ?? ''}
                onChange={handleChange}
                onBlur={handleChange}
                hasError={form?.Password.hasError && form?.Password.dirty}
                error={form?.Password.error}
                rightElement={
                  <S.ShowPasswordButton type='button' onClick={() => setShowPassword((oldState) => !oldState)}>
                    <Icon name='eye' size='s5' color='#808891' />
                  </S.ShowPasswordButton>
                }
              />

              <S.FormFooter>
                <Button kind='primary' type='submit' isLoading={isLoadingForm} isDisabled={handleValidateForm()}>
                  {label_login_button}
                </Button>

                <Link href={ROUTER_PATH.FORGOT_PASSWORD[defaultLocale]} passHref legacyBehavior>
                  <LinkComp kind={isLgBreakpoint ? 'inverse' : 'link'}>{label_forgot_password_link}</LinkComp>
                </Link>
              </S.FormFooter>

              {formError && <Text color='danger'>{formError}</Text>}
            </S.Form>

            <S.Aside>
              <Text as='h3' family='heading' size='lg' weight='bold' margin='0 0 1.5rem'>
                {aside?.aside_title}
              </Text>
              <Text color='weak' size='sm'>
                {aside?.aside_description}
              </Text>

              <Button kind='weak' isOutlined onClick={handleRegister}>
                {aside?.aside_label_button}
              </Button>
            </S.Aside>
          </S.WrapForm>
        </Col>
      </Row>
    </S.Content>
  )
}
